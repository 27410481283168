import React from 'react'
import style from "./Homepage.module.css"
import { Link } from 'react-router-dom';
import analytics from '../../firebase';
import { logEvent } from 'firebase/analytics';

const Footer = () => {
  const handleFooterAnalytics = (type) => {
    // Log the click event for Google Play button
    switch (type) {
      case 'gift':
        logEvent(analytics, "BN Gift Hamper Click", {
          platform: "Web",
        });
        break;
      case 'store':
        logEvent(analytics, "BN Store Click", {
          platform: "Web",
        });
        break;
    
      default:
        return
    }
  };
  return (
    <footer className={style.footer}>
      <div className={style.footerLinks}>
        <a className={style.link} href="/">
          BN App
        </a>{" "}
        <span className={style.separator}>•</span>
        <a
          className={style.link}
          href="https://forms.gle/DaaVxx46A8MB8ncN8"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleFooterAnalytics("gift")}
        >
          Planning & Gifting Help
        </a>
        <span className={style.separator}>•</span>
        <a
          className={style.link}
          href="https://1krjq3-sa.myshopify.com/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleFooterAnalytics("store")}
        >
          BN Store
        </a>
      </div>
      <div className={style.footerLinks}>
        <a
          className={style.link}
          href="/contact"
        >
          Contact Us
        </a>
        <span className={style.separator}>•</span>
        <a className={style.link} href="/terms">
          Terms & Conditions
        </a>
        <span className={style.separator}>•</span>
        <a className={style.link} href="/privacy">
          Privacy Policy
        </a>
      </div>
    </footer>
  );
}

export default Footer
